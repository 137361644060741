<template>
  <!-- 日历日程页 -->
  <el-card>
    <div class="calendar">
      <!-- <Tips></Tips> -->
      <!-- 第二部分 -->
      <div class="div">
        <!-- 日历 -->
        <div :class="showDivOpen||showStatistics ? 'calendar80' : 'calendar100'">
          <div v-show="showDateSelect" class="datePicker">
            <el-date-picker v-model="value" @change="changeValue" type="month" placeholder="选择年月"></el-date-picker>
          </div>
          <div class="right">
            <span>
              <el-select class="inputProject" v-model="projectid" multiple placeholder="请选择项目名称">
                <el-option
                  v-for="itemPro in projectList"
                  :key="'selectPro_'+itemPro.id"
                  :label="itemPro.name"
                  :value="itemPro.id"
                ></el-option>
              </el-select>
            </span>
            <span>
              <el-select class="inputTeacher" v-model="togetherid" multiple placeholder="请选择教师名称">
                <el-option
                  v-for="(item,index) in togetherList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </span>
            <el-button class="searchButton" @click="searchContent()">筛选</el-button>
            <!-- <el-button class="edit"  @click="showDialog('update')">修改</el-button> -->
            <el-button v-show="isEditClass" class="edit" @click="enterEdit">编辑</el-button>
            <el-button v-show="isStatistics" class="statistics" @click="statistics">统计</el-button>
            <el-button v-show="isOpenClass" class="open themeButton" @click="openDivShow()">开放日程</el-button>
          </div>
          <el-calendar v-model="value">
            <!-- slot 插槽 -->
            <template slot="dateCell" slot-scope="{ date, data }">
              <div>
                <!--这里原本有动态绑定的class，去掉-->
                <div class="date-el">
                  <el-checkbox
                    v-show="showcheckBox"
                    v-model="data.checkOneDay"
                    @change="checkOneDay(data)"
                  ></el-checkbox>
                  {{ data.day.split("-").slice(2).join("") }}
                </div>
                <table>
                  <tr
                    class="haveClass"
                    v-for="(item, index) in dealMyDate(data,date)"
                    :key="'open_'+index"
                  >
                    <td>
                      <el-checkbox
                        v-show="showcheckBox"
                        :disabled="item.disable"
                        v-model="item.checkModel"
                        @change="checked =>checkboxChange(checked,item.open_id)"
                      ></el-checkbox>
                    </td>
                    <el-tooltip placement="right-start" v-if="showcheckBox" effect="light">
                      <div slot="content" class="pointer">点击编辑</div>
                      <td @click="editOneOpen(item)">
                        <div>
                          {{ item.sksj }} -
                          {{ item.class_uname }}
                        </div>
                        <div>{{ item.name }} {{item.rs}}</div>
                        <div>{{ item.locainfo }}</div>
                      </td>
                    </el-tooltip>
                    <td
                      v-else
                      @click="openStudentsList(item.open_id,item.name,item.date,item.sksj,item.class_uname)"
                    >
                      <div>
                        {{ item.sksj }} -
                        {{ item.class_uname }}
                      </div>
                      <div>{{ item.name }} {{item.rs}}</div>
                      <div>{{ item.locainfo }}</div>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
          </el-calendar>
        </div>
      </div>
      <!-- 开放日程/修改开放日程 -->
      <div class="opendiv" v-if="showStatistics">
        <!-- 开放日程 -->
        <div class="changeCal">统计</div>
        <div class="closeImg" @click="closeStatistic">
          <img style="width:16px;height:16px" src="../../../assets/image/close.png" alt />
        </div>
        <!--日期范围 -->
        <div>
          <span>开始日期</span>
          <span>
            <span style="width:100%;display:inline-block;">
              <el-date-picker
                v-model="statisticsTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </span>
          </span>
        </div>
        <!-- 选择项目（课程关联） -->
        <div>
          <span>选择项目</span>
          <span>
            <el-select v-model="tongjiProjectId" placeholder="请选择" class="width100 themeInput">
              <el-option
                v-for="item in openProjectList"
                :key="'project_'+item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </span>
        </div>
        <br />
        <div class="center">
          <el-button class="close themecancelButton" @click="closeStatistic">关 闭</el-button>
          <el-button class="confirm themeButton" type="primary" @click="getStatistics">确 定</el-button>
        </div>
        <div class="staticInfo" v-show="showtongjiInfo">
          <div>
            <span class="staticItem">在修群组</span>
            <span class="staticItemData">{{tongjiList.zxqz}}</span>
          </div>
          <div>
            <span class="staticItem">在修人数</span>
            <span class="staticItemData">{{tongjiList.zxrs}}</span>
          </div>
          <div>
            <span class="staticItem">开放单元数</span>
            <span class="staticItemData">{{tongjiList.kfdys}}</span>
          </div>
          <div>
            <span class="staticItem">单元人数</span>
            <span class="staticItemData">{{tongjiList.dyrs}}</span>
          </div>
          <div>
            <div class="staticText">
              <div class="staticTextBox">
                <span class="staticItem">项目容量</span>
                <span class="staticItemData">{{tongjiList.xmrl}}</span>
              </div>
              <div class="staticTextBox">
                <span class="staticItem themeColor">开课率</span>
                <span class="staticItemData themeColor">{{tongjiList.kkl}}%</span>
              </div>
            </div>
            <div class="staticProgress">
              <el-progress
                color="#3E95B5"
                type="circle"
                width="50"
                :percentage="tongjiList.kkl"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
          <div>
            <div class="staticText">
              <div class="staticTextBox">
                <span class="staticItem">已预约人数</span>
                <span class="staticItemData">{{tongjiList.yyy}}</span>
              </div>
              <div class="staticTextBox">
                <span class="staticItem themeColor">预约完成度</span>
                <span class="staticItemData themeColor">{{tongjiList.yywcd}}%</span>
              </div>
            </div>
            <div class="staticProgress">
              <el-progress
                color="#3E95B5"
                type="circle"
                width="50"
                :percentage="tongjiList.yywcd"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
          <div>
            <div class="staticText">
              <div class="staticTextBox">
                <span class="staticItem">已完成人数</span>
                <span class="staticItemData">{{tongjiList.ywcrs}}</span>
              </div>
              <div class="staticTextBox">
                <span class="staticItem themeColor">实验完成度</span>
                <span class="staticItemData themeColor">{{tongjiList.sywcd}}%</span>
              </div>
            </div>
            <div class="staticProgress">
              <el-progress
                color="#3E95B5"
                type="circle"
                width="50"
                :percentage="tongjiList.sywcd"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>
      <!-- 开放日程/修改开放日程 -->
      <div class="opendiv" v-if="showDivOpen">
        <!-- 开放日程 -->
        <div class="changeCal" v-show="!showcheckBox">开放日程</div>
        <div class="closeImg" @click="closeOpenDiv">
          <img style="width:16px;height:16px" src="../../../assets/image/close.png" alt />
        </div>
        <div>
          <!-- 选择课程 -->
          <div v-if="is_open">
            <span>选择课程</span>
            <span>
              <el-select
                v-model="openCourseId"
                placeholder="请选择"
                class="width100 themeInput"
                clearable
                @change="courseChange()"
              >
                <el-option
                  v-for="item in courseList"
                  :key="'course_'+item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </span>
          </div>
          <!-- 选择项目（课程关联） -->
          <div v-if="is_open">
            <span>选择项目</span>
            <span class="selectProjectBox">
              <!-- <el-select  
              
                v-model="openProjectId" 
                placeholder="请选择" 
                multiple
                class="width100 themeInput" >
                <el-option
                  v-for="item in openProjectList"
                  :key="'project_'+item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select> -->
              <el-select  
                filterable
                remote
                clearable
                v-model="openProjectId" 
                placeholder="请选择" 
                multiple
                :remote-method="searchProjectList" 
                class="width100 themeInput" >
                <el-option
                  v-for="item in openProjectList"
                  :key="'project_'+item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </span>
          </div>
          <!-- 开始日期 -->
          <div>
            <div class="changeCal" v-show="showcheckBox">编辑日程</div>
            <!-- （删除+修改）按钮 + 搜索框 -->
            <div class="div" v-show="showcheckBox">
              <!-- 按钮 -->
              <div class="left">
                <!-- <el-button class="open" @click="openDivShow()"
                                >开放日程</el-button
                            >
                            <el-button class="edit"  @click="showDialog('update')"
                                >修改</el-button
                >-->
                <el-button class="close" size="small">
                  <el-checkbox size="mini" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                </el-button>
                <el-button
                  class="delete"
                  size="medium "
                  type="danger"
                  @click="showDialog('delete')"
                >删除</el-button>
              </div>
              <div
                class="pleaseChooseProject"
                v-show="showcheckBox"
              >{{project_name?project_name:'请选择编辑项目'}}</div>
              <!-- select下拉列表 -->
            </div>
            <span class="beginDateBox">开始日期</span>
            <span>
              <el-date-picker
                :disabled="isEdit"
                @change="weekChecked()"
                v-model="openDateValue"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                class="width100 themeInput"
              ></el-date-picker>
            </span>
          </div>
          <!-- 上课时间（时间段选择） -->
          <div>
            <span>上课时间(例如：07:00/12:07)</span>
            <span>
              <span style="width:50%;display:inline-block;">
                <!-- <el-time-select
                  :disabled="isEdit"
                  placeholder="起始时间"
                  v-model="openStartTime"
                  :picker-options="{
                                        start: '07:30',
                                        step: '00:01',
                                        end: '24:00'
                                    }"
                  class="width90 floatLeft themeInput"
                ></el-time-select> -->
                <el-input
                  :disabled="isEdit"
                  class="themeInput"
                  v-model="openStartTime"
                  placeholder="起始时间(07:00)"
                ></el-input>
              </span>
              <span style="width:50%;display:inline-block;">
                <!-- <el-time-select
                  :disabled="isEdit"
                  placeholder="结束时间"
                  v-model="openEndTime"
                  :picker-options="{
                                        start: '07:30',
                                        step: '00:01',
                                        end: '24:00'
                                    }"
                  class="width90 floatright themeInput"
                ></el-time-select> -->
                <el-input
                  :disabled="isEdit"
                  class="themeInput"
                  v-model="openEndTime"
                  placeholder="结束时间(12:00)"
                ></el-input>
              </span>
            </span>
          </div>
          <!-- 顺延周（默认0） -->
          <div v-if="is_open">
            <span>顺延周</span>
            <span>
              <el-input
                class="themeInput"
                type="number"
                min="0"
                v-model="openWeekNumber"
                placeholder="0 (不顺延)"
              ></el-input>
            </span>
          </div>
          <!-- 任课教师（查同事） -->
          <div>
            <span>任课教师</span>
            <span>
              <el-select v-model="openTeacherId" placeholder="请选择" class="width100 themeInput">
                <el-option
                  v-for="(item,index) in togetherList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </span>
          </div>
          <!-- 选择周（非必选） -->
          <div v-if="is_open">
            <span>选择周</span>
            <span>
              <el-checkbox
                v-model="item.check"
                @change=" checked =>checkboxWeekChange(checked, item.id)"
                v-for="(item, index) in weekList"
                :key="'week_'+index"
              >{{ item.name }}</el-checkbox>
            </span>
          </div>
          <!-- 按钮 -->
          <br />
          <div class="center">
            <el-button class="close themecancelButton" @click="closeOpenDiv()">关 闭</el-button>
            <!-- <el-button  :disabled="isEdit" class="confirm themeButton" type="primary" @click="execOpenUnit()">保 存</el-button> -->
            <el-button class="confirm themeButton" type="primary" @click="execOpenUnit()">保 存</el-button>
          </div>
        </div>
      </div>
      <!-- 开课弹窗 -->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span class="dialog-span">{{ dialogMsg }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="themecancelButton" @click="dialogVisible = false">取消</el-button>
          <el-button class="primary themeButton" @click="handleExec()">删除</el-button>
        </span>
      </el-dialog>

      <!-- 日程弹窗 -->
      <el-dialog
        title="开放单元学生列表"
        :visible.sync="dialogVisibleCalendar"
        width="50%"
        :close-on-click-modal="false"
      >
        <!-- 标题 + 二维码  -->
        <div></div>
        <!-- 复制学号 + 添加学生 按钮 -->
        <div></div>
        <!-- 学生列表 表格 -->
        <div>
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="date" label="日期" width="180"></el-table-column>
            <el-table-column prop="name" label="姓名" width="180"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <el-dialog title="开课提示" :visible.sync="openDateDialog" width="30%">
        <span class="dialog-span">您确认要在同一时间开放多个日程吗</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="themecancelButton" @click="openDateDialog = false">取消</el-button>
          <el-button class="primary themeButton" @click="confirmopen">确认</el-button>
        </span>
      </el-dialog>
    </div>
  </el-card>
</template>
<style lang="scss">
@import "@/assets/css/teacher/calendar/CalendarNoScope.scss";
</style>
<style lang="scss" scoped>
@import "@/assets/css/teacher/calendar/Calendar.scss";
</style>


<script>
import Calendar from "@/assets/js/teacher/calendar/calendar.js";
export default {
  ...Calendar
};
</script>
